import React from 'react';
// import './Footer.css';

function Footer() {
  return (
    <footer>
      {/* <p>made by AI42</p> */}
    </footer>
  );
}

export default Footer;
