import React from "react";
import axios from "axios";
import Web3Token from "web3-token";
import { SOCIAL_API } from "../../config";

async function addFollow(web3, token) {
  if (!web3) {
    alert("Please connect wallet");
    return;
  }

  // Get the first account address
  const accounts = await web3.eth.getAccounts();
  if (accounts.length === 0) {
    throw new Error("No accounts found.");
  }
  const user = accounts[0];

  // Generate a token with 1 day of expiration time
  const web3Token = await Web3Token.sign(
    (msg) => web3.eth.personal.sign(msg, user),
    "1d"
  );

  const data = {
    token,
  };

  // Configuration for the Axios request
  const config = {
    headers: {
      Authorization: `Bearer ${web3Token}`,
      "Content-Type": "application/json",
    },
  };

  await axios.post(SOCIAL_API + "/subscribe", data, config);
}

function Follow({ web3, token }) {
  return (
    <button
      style={{
        backgroundColor: "#86efac",
        height: "20px",
        marginTop: "10px",
        marginLeft: "10px",
      }}
      onClick={() => addFollow(web3, token)}>
      Follow
    </button>
  );
}

export default Follow;
