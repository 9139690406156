import React, { useState } from 'react';
import Web3Context from '../../context/Web3Context'
import { nets, PUMP_API } from '../../config'
import PumpsFactoryABI from '../../abi/PumpsFactoryABI'
import UniV2RouterABI from '../../abi/UniV2RouterABI'
import { toWei } from 'web3-utils';
import axios from 'axios';

function CreateVirtLiqudity() {
  const context = React.useContext(Web3Context)
  const web3 = context.web3
  const accounts = context.accounts
  const netId = Number(context.netId)

  const [formData, setFormData] = useState(
    { 
      name: '', 
      symbol: '', 
      supply: 0, 
      net:'tbnb',
      image: null,
    }
  );

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    console.log(formData); // Handle form submission logic here
  };

  const createPum = async (web3, accounts) => {
    if(!web3){
      alert("Please connect wallet")
      return 
    }

    if(nets[formData.net].netID !== netId){
      alert(`Please switch wallet to ${nets[formData.net].symbol}`)
      return
    }

    if(formData.name.length === 0){
      alert("Please input name")
      return
    }


    if(formData.symbol.length === 0){
      alert("Please input symbol")
      return
    }

    if(formData.supply <= 0){
      alert("Please input supply")
      return
    }

    if(!formData.image){
      alert("Please drop image")
      return
    }

    let uri

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('image', formData.image)

      const response = await axios.post(`${PUMP_API}/upload`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      uri = response.data.file

      console.log('File uploaded successfully', response.data);
    } catch (error) {
      alert("Can not upload image")
      console.error('Error uploading file', error)
      return
    }

    const factory = new web3.eth.Contract(PumpsFactoryABI, nets[formData.net].factory)

    console.log("nets[formData.net].factory", nets[formData.net].factory, formData.net)
    
    const router = new web3.eth.Contract(UniV2RouterABI, nets[formData.net].v2Router)
    const rate = await router.methods.getAmountsOut(
      nets[formData.net].daiInitial,
      [nets[formData.net].dai, nets[formData.net].weth]
    ).call()

    const liquidity = rate[1]

    console.log(
      "Params", 
      formData.name, 
      formData.symbol, 
      toWei(String(formData.supply)),
      liquidity,
      uri
    )

    const gasPrice = await web3.eth.getGasPrice()

    factory.methods.newSale(
      formData.name, 
      formData.symbol, 
      toWei(String(formData.supply)),
      liquidity,
      uri
    ).send({ from:accounts[0], gasLimit:3500848, gasPrice })
  }

  return (
    <div className="App">
        <form className="form" onSubmit={handleSubmit}>
          <label className="label">
            Name:
            <input className="input" type="text" name="name" value={formData.name} onChange={handleInputChange} />
          </label>
          <br />
          <label className="label">
            Symbol:
            <input className="input" type="text" name="symbol" value={formData.symbol} onChange={handleInputChange} />
          </label>
          <br />
          <label className="label">
            Supply:
            <input className="input" type="number" name="supply" value={formData.supply} onChange={handleInputChange} />
          </label>
          <br />
          <label className="label">
            Image:
            <input className="input" type="file" name="image" onChange={handleInputChange} />
          </label>
          <br />
          <label className="label">
            Network:
            <select className="input" name="net" value={formData.net} onChange={handleInputChange}>
            {
                Object.entries(nets).map(([key, value]) => (
                    <option key={key} value={key}>{value.symbol}</option>
                ))
            }
            </select>
          </label>
          <br />
          <button className="submitButton" onClick={() => createPum(web3, accounts)}>Create</button>
        </form>
    </div>
  );
}

export default CreateVirtLiqudity;