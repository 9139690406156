import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
// import './CoinItem.css';

function CoinItem({ token }) {
  return (
    // <Box sx={{ display: "flex", gap: 1 }}>
    // <img src={token.image} alt={token.name} className="image" />
    // <Stack my={2} gap={1}>
    //   <Typography variant="body2" sx={{ fontSize: "13px", color: "#B5D2F7" }}>
    //     {token.name}
    //   </Typography>
    //   <Typography variant="body2" sx={{ color: "#86EFAC", fontSize: "13px" }}>
    //     market cap: {Number(token.marketCap).toFixed()}
    //   </Typography>
    //   <Typography variant="body2" sx={{ color: "#939AA6", fontSize: "12px" }}>
    //     net: {token.net}
    //   </Typography>

    <Link
      to={`/token-details/${token.token}/${String(token.net).toLowerCase()}/${String(token.isVirt)}`}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          ":hover": { border: "1px solid #fff", cursor: "pointer" },
          px: 2,
          py: 1,
          backgroundColor: "#1e1e1e",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
          width: { xs: "98%", md: "90%" },
          height: "180px",
        }}
      >
        <Box sx={{ display: "flex", gap: "20px" }}>
          <img
            src={token.image}
            alt={token.name}
            style={{ width: "80px", height: "80px", borderRadius: "8px" }}
          />
          <Stack my={1} gap={1} sx={{ flexGrow: 1, width: "100%" }}>
            <Typography
              variant="h6"
              sx={{ color: "#FFFFFF", fontSize: "14px" }}
            >
              {token?.name}
            </Typography>
            <Typography variant="body2" sx={{ color: "#939AA6" }}>
              {/* Market cap {token.marketCap} */}
              {token?.description}
            </Typography>
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexGrow: "1",
            alignItems: "center",
          }}
        >
          {/* 
          <Typography variant="body2" sx={{ color: "#86EFAC" }}>
            replies: {token.replies}
          </Typography> 
          */}
          <Typography variant="body2" sx={{ color: "#86EFAC" }}>
            market cap:{Number(token?.marketCap).toFixed()}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "#939AA6", fontSize: "12px" }}
          >
            replies: {token?.post_count}
          </Typography>

          <Typography
            variant="body2"
            sx={{ color: "#86EFAC", fontSize: "12px" }}
          >
            virtual: {token?.isVirt ? "yes" : "no"}
          </Typography>

          <Typography variant="body2" sx={{ color: "#939AA6" }}>
            net: {token?.net}
          </Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          value={100}
          // value={token.percentage}
          sx={{
            height: "8px",
            borderRadius: "4px",
            backgroundColor: "#2D3748",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#48BB78",
            },
          }}
        />
      </Box>
      {/* <button style={{ backgroundColor:"#86efac" }}>Details</button> */}
    </Link>

    // {/*
    // <Typography variant="body1" sx={{ color: "#939AA6", fontSize: "15px" }}>
    //   <span style={{ fontWeight: 600 }}>
    //     {token.name} (ticker: {token.ticker}):{" "}
    //   </span>
    //   {token.description}
    // </Typography>
    // */}
    //   </Stack>
    // </Box>
  );
}

export default CoinItem;
