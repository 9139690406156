import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
// import './CoinItem.css';

function NewCoin({ token }) {
  return (
    <Link
      to={`/token-details/${token?.token}/${String(token?.net).toLowerCase()}`}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          ":hover": { border: "1px solid #fff", cursor: "pointer" },
          px: 2,
          py: 1,
          backgroundColor: "#1e1e1e",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Box sx={{ display: "flex", gap: "20px" }}>
          <img
            src={token?.image}
            alt={token?.name}
            style={{ width: "80px", height: "80px", borderRadius: "8px" }}
          />
          <Stack my={1} gap={1} sx={{ flexGrow: 1, width: "100%" }}>
            <Typography
              variant="h6"
              sx={{ color: "#FFFFFF", fontSize: "14px" }}
            >
              Created by {token?.name}
            </Typography>
            <Typography variant="body2" sx={{ color: "#939AA6" }}>
              {/* Market cap {token?.marketCap} */}
              {token?.description}
            </Typography>
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexGrow: "1",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {/* <Typography variant="body2" sx={{ color: "#86EFAC" }}>
            replies: {token?.replies}
          </Typography> */}
          <Typography variant="body2" sx={{ color: "#86EFAC" }}>
            market cap:{Number(token?.marketCap).toFixed(2)}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "#939AA6", fontSize: "12px" }}
          >
            replies: {token?.post_count}
          </Typography>
          <Typography variant="body2" sx={{ color: "#939AA6" }}>
            net: {token?.net}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
}

export default NewCoin;
