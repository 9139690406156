import React from 'react';
import CoinItem from './CoinItem';
import { Grid } from '@mui/material';
// import './CoinList.css';

function CoinList({ tokens }) {
  return (
    <Grid
    container
    spacing={2}
    justifyContent="flex-start"
    alignItems="stretch"
  >
    {tokens.map((token) => (
      <Grid item key={token.token} xs={12} sm={6} md={4}>
        <CoinItem token={token} />
      </Grid>
    ))}
  </Grid>
  );
}

export default CoinList;
