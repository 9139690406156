import React, { useState } from 'react';
import Header from './components/Header';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import TokenDetails from './components/TokenDetails/TokenDetails'
import CreateNewPump from './components/CreateNewPump/CreateNewPump'
import Web3Context from './context/Web3Context';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { isDEV } from './config'

function App() {
  const [web3, setWeb3] = useState(null)
  const [accounts, setAccounts] = useState(null)
  const [netId, setNetId] = useState(null)

  return (
    <div className="App">
      <Web3Context.Provider value={{ web3, setWeb3, accounts, setAccounts, netId, setNetId }}>
      <Router>
      <Header />
      {
        isDEV
        ?
        (
           <> WARNING YOU ARE IN DEV MODE</>
        )
        : null
      }
        <Routes>
          <Route path="/" element={<MainContent  />} />
          <Route path="/token-details/:address/:net/:virtual" element={<TokenDetails />} />
          <Route path="/create-new-pump" element={<CreateNewPump />} />
        </Routes>
        <Footer />
      </Router>
      </Web3Context.Provider>
   </div>
  );
}

export default App;
