function removeDuplicates(data) {
  let uniqueData = [];
  let seen = new Set();
  
  for (let item of data) {
      if (!seen.has(item.block)) {
          uniqueData.push(item);
          seen.add(item.block);
      }
  }
  
  return uniqueData;
}

export default removeDuplicates;