import React, { useState, useEffect } from "react";
import CoinList from "./CoinList";
import axios from "axios";
import { PUMP_API, SOCIAL_API, WHALEX_API } from "../config";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import NewCoin from "./NewCoin";
import { Link } from "react-router-dom";
// import './MainContent.css';
import Web3Context from '../context/Web3Context'

async function loadData(setTokens) {
  let tokens = []
  
  // load virtual liqudity tokens
  try{
    const arr1 = await axios.get(PUMP_API + "/tokens")
    const arrT1 = arr1.data.result
    for(let i = 0; i < arrT1.length; i++){
      arrT1[i].isVirt = true 
    }
    tokens.push(...arrT1)
  }catch(e){
    alert("Cant load virt liquidity tokens")
    console.log("err : ", e)
  }

   // load real liqudity tokens
   try{
    const arr2 = await axios.get(WHALEX_API + "/tokens");
    const arrT2 = arr2.data.result
    for(let i = 0; i < arrT2.length; i++){
      arrT2[i].isVirt = false 
    }
    tokens.push(...arrT2)
  }catch(e){
    alert("Cant load real liquidity tokens")
    console.log("err : ", e)
  }
  
  // load data about posts per token
  try{
    const data2 = await axios.get(SOCIAL_API + "/tokens-posts");
    const social = data2.data.result
    const combinedArray = tokens.map(token => {
      const socialObject = social.find(social => String(social.token).toLowerCase() === String(token.token).toLowerCase());
      return {
          ...token,
          post_count: socialObject ? socialObject.post_count : 0,
          lastUpdateTime: socialObject ? socialObject.lastUpdateTime : 0
      };
    });
    tokens = combinedArray
  }catch(e){
    console.log("social e", e)
    for(let i = 0; i < tokens.length; i++){
      tokens[i].post_count = 0
    }
  }
  console.log("tokens", tokens)
  setTokens(tokens);
}

async function loadUserFollows(accounts, tokens, setFolows, setIsFWLoad){
  const data = await axios.get(SOCIAL_API + "/follows/" + accounts[0]);
  const social = data.data.result

  if(social){
    const folws = JSON.parse(social.tokensObj)
    const filtered = tokens.filter(item => folws.includes(item.token))

    setFolows(filtered)
  }

  setIsFWLoad(false)
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MainContent() {
  const [tokens, setTokens] = useState([]);
  const [follows, setFolows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTokens, setFilteredTokens] = useState(tokens);
  const [sortValue, setSortValue] = useState("last reply");
  const [value, setValue] = useState(1);
  const [isFWLoad, setIsFWLoad] = useState(true)

  const context = React.useContext(Web3Context)
  const web3 = context.web3
  const accounts = context.accounts
  
  // load data
  useEffect(() => {
    loadData(setTokens);
  }, [setTokens]);
  
  // load my follows
  useEffect(() => {
    if(web3 && accounts){
      loadUserFollows(accounts, tokens, setFolows, setIsFWLoad)
    }
  }, [web3, accounts, tokens, setFolows, setIsFWLoad])
  
  // apply sort dy desk and ask and search
  useEffect(() => {
    if (tokens.length > 0) {
      let filtered = tokens.filter((token) =>
        token.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      if(sortValue === "last reply"){
        filtered = filtered.sort((a, b) => b.lastUpdateTime - a.lastUpdateTime);
      }

      if(sortValue === "reply count"){
        filtered = filtered.sort((a, b) => b.post_count - a.post_count);
      }

      if(sortValue === "market cap desc"){
        filtered = filtered.sort((a, b) => b.marketCap - a.marketCap);
      }

      if(sortValue === "market cap asc"){
        filtered = filtered.sort((a, b) => a.marketCap - b.marketCap);
      }

      if(sortValue === "creation time"){
        filtered = filtered.sort((a, b) => b.createdTime - a.createdTime);
      }

      setFilteredTokens(filtered);
    }
  }, [tokens, searchTerm, sortValue]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchButton = (e) => {
    e.preventDefault();
    setSearchTerm(searchTerm);
  };

  const handleSortChange = (e) => {
    setSortValue(e.target.value);
  };

  return (
    <main>
      {/* <div className="controls">
        <input
          type="text"
          placeholder="search for token"
          value={searchTerm}
          onChange={handleSearch}
        />
       <Select onChange={handleSortOrderChange} value={sortOrder} style={{backgroundColor:"#86EFAC",border:"none",borderRadius:"8px",height:"40px"}}>
          <MenuItem value="desc">Order: desc</MenuItem>
          <MenuItem value="asc">Order: asc</MenuItem>
        </Select>
      </div>
      <CoinList tokens={filteredTokens} /> */}
      <Stack sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Link
          to="/create-new-pump"
          style={{ color: "white", textDecoration: "none" }}
        >
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              ":hover": { fontWeight: 600 },
              cursor: "pointer",
            }}
          >
            [start a new coin]
          </Typography>
        </Link>
        <Box>
          <img
            src="https://pump.fun/_next/image?url=%2Fking-of-the-hill.png&w=256&q=75"
            alt="logo"
            width={150}
          />
        </Box>
        <Box>
          {tokens && <NewCoin token={tokens[Math.floor(Math.random() * tokens.length)]} />}
        </Box>
      </Stack>
      <Box textAlign={"center"} sx={{ my: 3 }}>
        <input
          style={{
            backgroundColor: "#86EFAC",
            height: "40px",
            width: "30%",
            border: "none",
            borderRadius: "8px",
            padding: 1,
          }}
          type="text"
          placeholder="search for token"
          value={searchTerm}
          onChange={handleSearch}
        />
        <Button
          sx={{
            color: "#000",
            bgcolor: "#86EFAC",
            p: 1,
            ml: 1,
            ":hover": { bgcolor: "#22C55E" },
          }}
          onClick={handleSearchButton}
        >
          Search
        </Button>
      </Box>

      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab sx={{ color: "grey" }} label="Following" {...a11yProps(0)} />
          <Tab sx={{ color: "grey" }} label="Terminal" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {
          web3
          ?
          (
            <>
            {
              isFWLoad
              ?
              (
                <>searching ...</>
              )
              : null
            }
             <CoinList tokens={follows} />
            </>
          )
          :
          (
            <>Connect your wallet to see all your follows</>
          )
        }
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Stack flexDirection={{ xs: "column", md: "row" }} gap={1}>
          <Box className="controls">
            <Select
              onChange={handleSortChange}
              value={sortValue}
              style={{
                backgroundColor: "#86EFAC",
                border: "none",
                borderRadius: "8px",
                height: "40px",
              }}
            >
              <MenuItem value="last reply">sort: last reply</MenuItem>
              <MenuItem value="reply count">sort: reply count</MenuItem>
              <MenuItem value="creation time">sort: creation time</MenuItem>
              <MenuItem value="market cap desc">sort: market cap desc</MenuItem>
              <MenuItem value="market cap asc">sort: market cap asc</MenuItem>
            </Select>
          </Box>
          {/*
          <Box>
            <Select
              onChange={handleSortOrderChange}
              value={sortOrder}
              style={{
                backgroundColor: "#86EFAC",
                border: "none",
                borderRadius: "8px",
                height: "40px",
              }}
            >
              <MenuItem value="desc">order: desc</MenuItem>
              <MenuItem value="asc">order: asc</MenuItem>
            </Select>
          </Box>
          */}
          {/* 
          <Box>
            <Box sx={{ display: "flex" }}>
              <Typography>Show animations:</Typography>
              <Typography sx={{ color: "lightgray", mx: 1 }}>On</Typography> 
              
              <Typography
                sx={{
                  bgcolor: "#86EFAC",
                  borderRadius: "5px",
                  px: "5px",
                  color: "#000",
                }}
              >
                Off
              </Typography>
            </Box>
            */}
            
            {/* 
            <Box sx={{ display: "flex" }}>
              <Typography>Include nsfw:</Typography> 
              <Typography sx={{ color: "lightgray", mx: 1 }}>On</Typography>
              
              <Typography
                sx={{
                  bgcolor: "#86EFAC",
                  borderRadius: "5px",
                  px: "5px",
                  color: "#000",
                }}
              >
                Off
              </Typography>
            </Box>
          </Box>
           */}
        </Stack>
        <CoinList tokens={filteredTokens} />
      </CustomTabPanel>
    </main>
  );
}

export default MainContent;
