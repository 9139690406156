import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { 
  PUMP_API, 
  PUMP_CHARTS_API, 
  SOCIAL_API, 
  WHALEX_API, 
  WHALEX_CHARTS_API 
} from "../../config";
import { Typography, Box } from "@mui/material";
import Web3Context from "../../context/Web3Context";
import TradeChart from "../TradeChart";
import removeDuplicates from "../../helpers/removeDuplicates";
import Web3Token from "web3-token";
import { MetaMaskAvatar } from "react-metamask-avatar";
import VirtualSwap from "./VirtualSwap";
import Follow from "./Follow";
import RealSwap from "./RealSwap";

async function updateComments(token, setComments) {
  try {
    const data3 = await axios.get(SOCIAL_API + "/posts/" + token);
    console.log("data3", data3.data.result);

    if (data3.data.result) {
      setComments(JSON.parse(data3.data.result.posts));
    }
  } catch (e) {
    alert("Can not load comments");
    console.log("comments e: ", e);
  }
}

async function loadData(
  address,
  net,
  virtual,
  setTokenInfo,
  setTradesData,
  setComments
) {
  const api_uri = virtual === 'true' ? PUMP_API : WHALEX_API
  // todo api for not virtual pools
  const aggregator_uri = virtual === 'true' ? PUMP_CHARTS_API : WHALEX_CHARTS_API
  const data = await axios.get(api_uri + "/token/" + address + "/" + net);
  setTokenInfo(data.data.result);

  try {
    const data2 = await axios.get(
      aggregator_uri + "/pair/" + data.data.result.sale
    );

    const filtred = removeDuplicates(JSON.parse(data2.data.history[0].trades));
    setTradesData(filtred);
  } catch (e) {
    alert("Can not load charts");
    console.log("charts e: ", e);
    setTradesData([])
  }

  updateComments(address, setComments);
}

function TokenDetails() {
  const { address, net, virtual } = useParams();
  const context = React.useContext(Web3Context);
  const web3 = context.web3;
  const accounts = context.accounts;
  const [tokenInfo, setTokenInfo] = useState({
    sale: "loading",
    image: "",
    name: "loading",
    creator: "",
  });
  const [tradesData, setTradesData] = useState([]);
  const [post, setPost] = useState("");
  const [comments, setComments] = useState([]);

  async function deletePost(postID) {
    if (!web3) {
      alert("Please connect wallet");
      return;
    }

    // Get the first account address
    const accounts = await web3.eth.getAccounts();
    if (accounts.length === 0) {
      throw new Error("No accounts found.");
    }
    const user = accounts[0];

    // Generate a token with 1 day of expiration time
    const token = await Web3Token.sign(
      (msg) => web3.eth.personal.sign(msg, user),
      "1d"
    );

    const data = {
      token: address,
      postID,
    };

    // Configuration for the Axios request
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    await axios.post(SOCIAL_API + "/delete-post", data, config);

    const updated = comments.filter((post) => post.postID !== postID);
    setComments(updated);
  }

  async function addComment() {
    if (!web3) {
      alert("Please connect wallet");
      return;
    }

    if (post.length === 0) {
      alert("Please add some text");
      return;
    }

    // Get the first account address
    const accounts = await web3.eth.getAccounts();
    if (accounts.length === 0) {
      throw new Error("No accounts found.");
    }
    const user = accounts[0];

    // Generate a token with 1 day of expiration time
    const token = await Web3Token.sign(
      (msg) => web3.eth.personal.sign(msg, user),
      "1d"
    );

    const data = {
      token: address,
      post: post,
    };

    // Configuration for the Axios request
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    await axios.post(SOCIAL_API + "/add-post", data, config);

    updateComments(address, setComments);

    setPost("");
  }

  useEffect(() => {
    if (address) {
      loadData(address, net, virtual, setTokenInfo, setTradesData, setComments);
    }
  }, [address, net, virtual, setTokenInfo, setTradesData, setComments]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", md: "row" },
        justifyContent: "space-between",
        gap: 1,
      }}>
      <Box
        sx={{
          display: "flex",
          width: { xs: "100%", sm: "80%", md: "70%" },
          paddingLeft: { xs: "0px", md: "40px" },
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          alignItems: "center",
          gap: 6,
        }}>
        <Box
          sx={{ flexDirection: { xs: "column", md: "row", display: "flex" } }}
          my={2}
          gap={1}>
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
            }}>
            <Box
              sx={{
                flexDirection: { xs: "column", md: "row", display: "flex" },
                display: "flex",
                gap: "10px",
                paddingLeft: "5px",
              }}>
              <Typography
                variant="body2"
                sx={{ color: "#86EFAC", fontSize: "9px" }}>
                NET: {net}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#86EFAC", fontSize: { xs: "6px", md: "7px" } }}>
                Token: {address}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#86EFAC", fontSize: { xs: "6px", md: "7px" } }}>
                Sale: {tokenInfo.sale}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#86EFAC",
                  fontSize: { xs: "6px", md: "7px" },
                }}>
                Creator: {tokenInfo.creator}
              </Typography>
            </Box>
            {tradesData.length > 0 ? (
              <TradeChart trades={tradesData} />
            ) : (
              <Typography
                variant="body2"
                sx={{ color: "yellow", fontSize: "13px" }}>
                No trades yet
              </Typography>
            )}
            <>
              <input
                type="text"
                value={post}
                placeholder={`Leave your comment`}
                onChange={(e) => setPost(e.target.value)}
                style={{
                  width: "220px",
                  padding: "10px",
                  fontSize: "14px",
                  marginTop: "10px",
                }}
              />
              <Box sx={{ display: "flex" }}>
                <button
                  style={{
                    backgroundColor: "#86efac",
                    width: "110px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onClick={() => addComment()}>
                  Add comment
                </button>
                <Follow web3={web3} token={address} />
              </Box>
              {comments.length > 0 ? (
                <>
                  Comments
                  {comments.map((item) => {
                    return (
                      <div key={item.postID}>
                        <MetaMaskAvatar address={item.creator} size={24} />
                        <Typography
                          variant="body2"
                          sx={{ color: "white", fontSize: "8px" }}>
                          {item.creator.slice(0, -18)}...
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "yellow", fontSize: "18px" }}>
                          {item.post}
                        </Typography>
                        {accounts &&
                        String(item.creator).toLowerCase() ===
                          String(accounts[0]).toLowerCase() ? (
                          <Typography
                            variant="body2"
                            sx={{ color: "grey", fontSize: "10px" }}
                            onClick={() => deletePost(item.postID)}>
                            delete
                          </Typography>
                        ) : null}
                      </div>
                    );
                  })}
                </>
              ) : null}
            </>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: { xs: "auto", md: "200px" },
          paddingRight: { xs: "8px", md: "20px", lg: "48px" },
          paddingLeft: { xs: "37px", md: "20px", lg: "48px" },
          paddingTop: "18px",
        }}>

        {
          virtual === 'true'
          ?
          (
            <VirtualSwap
             net={net}
             sale={tokenInfo.sale}
             name={tokenInfo.name}
             token={address}
            />
          )
          :
          (
            <RealSwap
             net={net}
             sale={tokenInfo.sale}
             name={tokenInfo.name}
             token={address}
            />
          )
        }
       

        <Box
          sx={{
            flexDirection: { xs: "column" },
            display: "flex",
            marginLeft: "6px",
          }}>
          <Box
            sx={{
              display: "flex",
            }}>
            <img
              style={{
                objectFit: "cover",
                height: "80px",
                width: "80px",
              }}
              src={tokenInfo.image}
              alt={tokenInfo.name}
              className="big-image"
            />
            <Typography
              variant="body2"
              sx={{
                color: "#86EFAC",
                fontSize: "13px",
                paddingTop: "20px",
                paddingLeft: "10px",
              }}>
              NET: {net}
            </Typography>
          </Box>
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              gap: "10px",
              paddingLeft: "5px",
            }}>
            <Typography
              variant="body2"
              sx={{ color: "#86EFAC", fontSize: "13px" }}>
              Token: {address}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#86EFAC", fontSize: "13px" }}>
              Sale: {tokenInfo.sale}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#86EFAC", fontSize: "13px" }}>
              Creator: {tokenInfo.creator}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default TokenDetails;
