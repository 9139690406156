import React, { useEffect, useState }  from 'react';
import Wallet from './Wallet'
import { Link } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import axios from "axios";
import { PUMP_API } from "../config";
import { isMobile } from "react-device-detect";
// import './Header.css';

async function loadData(setTokens) {
  const data = await axios.get(PUMP_API + "/tokens");
  setTokens(data.data.result);
}

function Header(props) {
  const [isShaking, setIsShaking] = useState(false);
  const [tokens, setTokens] = useState([]);

  useEffect(() => {
    loadData(setTokens);
  }, [setTokens]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 500);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <header>
      <nav className="header-nav">
        {/* <Link to="/">
        <button style={{ backgroundColor:"#86efac" }}>Main page</button>
        </Link>
        <Link to="/create-new-pump">
        <button style={{ backgroundColor:"#86efac" }}>Start A New Coin</button>
        </Link>
        <Wallet props={props}/>
        <a href="#twitter">[twitter]</a>
        <a href="#support">[support]</a>
        <a href="#telegram">[telegram]</a> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "98vw",
            p: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link to="/" style={{ color: "#fff", textDecoration: "none" }}>
             <img src={"./logo.webp"} alt="logo" width={20} />
              {/* <img src={"./images/logo.png"} alt="logo" width={50} /> */}
            </Link>
            <Stack>
              <Box sx={{ mb: "5px" }}>
                <Link
                  href="https://x.com/?lang=en"
                  sx={{
                    color: "#fff",
                    textDecoration: "none",
                    cursor: "pointer",
                    m: 1,
                    fontSize: "15px",
                    ":hover": { fontWeight: 600, textDecoration: "underline" },
                  }}
                >
                  [twitter]
                </Link>
                <Link
                  sx={{
                    color: "#fff",
                    textDecoration: "none",
                    cursor: "pointer",
                    m: 1,
                    fontSize: "15px",
                    ":hover": { fontWeight: 600, textDecoration: "underline" },
                  }}
                >
                  [support]
                </Link>
              </Box>
              <Box>
                <Link
                  href="https://web.telegram.org/a/"
                  sx={{
                    color: "#fff",
                    textDecoration: "none",
                    cursor: "pointer",
                    m: 1,
                    fontSize: "15px",
                    ":hover": { fontWeight: 600, textDecoration: "underline" },
                  }}
                >
                  [telegram]
                </Link>
                <Link
                  sx={{
                    color: "#fff",
                    textDecoration: "none",
                    cursor: "pointer",
                    m: 1,
                    fontSize: "15px",
                    ":hover": { fontWeight: 600 },
                  }}
                >
                  [how it works]
                </Link>
              </Box>
            </Stack>
            {!isMobile && (
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <Typography
                  variant="body1"
                  className="notification"
                  sx={{ bgcolor: getRandomColor(), color: "#000" }}
                  style={{
                    animation: isShaking ? "shake 0.5s infinite" : "none",
                  }}
                >
                  {tokens[Math.floor(Math.random() * tokens.length)]?.name} market cap{" "}
                  {tokens[Math.floor(Math.random() * tokens.length)]?.marketCap} of{" "}
                  {tokens[Math.floor(Math.random() * tokens.length)]?.net}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ bgcolor: "#93CFFD", color: "#000" }}
                  className="notification"
                >
                  {tokens?.[0]?.name} created {tokens?.[0]?.net} with{" "}
                  {tokens?.[0]?.marketCap}
                </Typography>
              </Box>
            )}
          </Box>
          <Wallet props={props} />
        </Box>
      </nav>
    </header>
  );
}

export default Header;
