import React, { useState, useEffect, useCallback } from 'react';
import { Box, Tab, Button, TextField, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import Web3Context from '../../context/Web3Context'
import SaleABI from '../../abi/SaleABI'
import ERC20_ABI from '../../abi/ERC20_ABI'
import { toWei, fromWei } from 'web3-utils'
import Web3 from "web3";
import { nets } from '../../config'

const useStyles = makeStyles({
  root: {
    background: '#2E2F33',
    borderRadius: '10px',
    padding: '20px',
    maxWidth: '300px',
    margin: 'auto',
    color: '#fff',
  },
  tabList: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  tab: {
    flex: 1,
    textAlign: 'center',
    '&.Mui-selected': {
      color: '#000',
    },
    '&:not(.Mui-selected)': {
      background: '#333',
      color: '#fff',
    },
  },
  buyTab: {
    '&.Mui-selected': {
      background: '#00E676',
    },
  },
  sellTab: {
    '&.Mui-selected': {
      background: '#F44336',
    },
  },
  textField: {
    marginBottom: '10px',
    width: '100%',
    '& .MuiInputBase-root': {
      color: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#fff',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00E676',
      },
    },
  },
});


export default function Swap({ net, sale, name, token }) {
  const context = React.useContext(Web3Context)
  const web3 = context.web3
  const accounts = context.accounts
  const netId = Number(context.netId)

  const classes = useStyles();
  const [value, setValue] = useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [ethAmount, setEthAmount] = useState(0)
  const [tokenAmount, setTokenAmount] = useState(0)
  const [sellTokenAmount, setSellTokenAmount] = useState(0)
  const [buyEthAmount, setBuyEthAmount] = useState(0)
  const [isRequireApprove, setIsRequireApprove] = useState(0)
  
  
  // get price eth to token 
  const computeTokenPrice = useCallback(async (amount, setTokenAmount) => {
    const rpc = nets[net].rpc
    const _web3 = new Web3(rpc)
    const saleContract = new _web3.eth.Contract(SaleABI, sale)
    const amountWei = toWei(String(amount))
    const price = await saleContract.methods.getTokenPriceByETHInput(amountWei).call()
    setTokenAmount(Number(fromWei(String(price))).toFixed(8))
  }, [net, sale])
  
  // compute price token to eth
  const computeETHPrice = useCallback(async (amount, setBuyEthAmount) => {
    const rpc = nets[net].rpc
    const _web3 = new Web3(rpc)
    const saleContract = new _web3.eth.Contract(SaleABI, sale)
    const amountWei = toWei(String(amount))
    const price = await saleContract.methods.getETHPriceByTokenInput(amountWei).call()
    setBuyEthAmount(Number(fromWei(String(price))).toFixed(8))
  }, [net, sale])
  
  // check if token approved
  const checkApprove = useCallback(async (amount) => {
    const tokenContract = new web3.eth.Contract(ERC20_ABI, token)
    const allowance = await tokenContract.methods.allowance(accounts[0], sale).call()
    const allowed = Number(allowance) > 0
    ? Number(allowance) / 10**18
    : 0 
  
    if(amount > allowed){
      setIsRequireApprove(true)
    }
  }, [accounts, sale, token, web3])
  
  // approve
  const approve = async () => {
    const tokenContract = new web3.eth.Contract(ERC20_ABI, token)
    setIsRequireApprove(false)
    const MAX = "57896044618658097711785492504343953926634992332820282019728792003956564819967"
    tokenContract.methods.approve(sale, MAX)
    .send({ from:accounts[0] })
  }
  
  // update token price
  useEffect(() => {
    if(ethAmount > 0){
      computeTokenPrice(ethAmount, setTokenAmount)
    }else{
      setTokenAmount(0)
    }
  }, [web3, ethAmount, computeTokenPrice, setTokenAmount])

  // update eth price
  useEffect(() => {
    if(sellTokenAmount > 0){
      computeETHPrice(sellTokenAmount, setBuyEthAmount)
    }else{
      setBuyEthAmount(0)
    }
  }, [web3, sellTokenAmount, computeETHPrice, setBuyEthAmount])

   // check approve
   useEffect(() => {
    if(web3 && sellTokenAmount > 0)
      checkApprove(sellTokenAmount)
  }, [web3, sellTokenAmount, checkApprove])

  // helper for check wallet and net
  function checkBeforeTx() {
    if (!web3) {
        alert("Please connect wallet");
        return false;
    }

    if (netId !== nets[net].netID) {
        alert(`Please switch to ${nets[net].symbol}`);
        return false;
    }

    return true;
  }
  
  // confirm transaction buy
  async function buy(){
    if (!checkBeforeTx()) {
      return; 
    }
    
    // check eth balance
    const balance = fromWei(String(await web3.eth.getBalance(accounts[0])))
    if(ethAmount > balance){
      alert(`Not enough ${net} balance`)
      return
    }

    const saleContract = new web3.eth.Contract(SaleABI, sale)
    saleContract.methods.Buy(1).send({ from:accounts[0], value:toWei(String(ethAmount)) })
  }

  // confirm transaction sell
  async function sell(){
    if (!checkBeforeTx()) {
      return; 
    }

    const saleContract = new web3.eth.Contract(SaleABI, sale)
    saleContract.methods.Sell(toWei(String(sellTokenAmount)), 1).send({ from:accounts[0] })
  }

  return (
    <Box className={classes.root}>
      <TabContext value={value}>
        <Box className={classes.tabList}>
          <TabList onChange={handleChange} aria-label="swap tabs">
            <Tab label="Buy" value="1" className={`${classes.tab} ${value === '1' ? classes.buyTab : ''}`} />
            <Tab label="Sell" value="2" className={`${classes.tab} ${value === '2' ? classes.sellTab : ''}`} />
          </TabList>
        </Box>

        {/* BUY */}
        <TabPanel value="1" >
          <TextField 
            variant="outlined" 
            defaultValue="0.0" 
            InputProps={{ endAdornment: <Typography>{net}</Typography> }} 
            className={classes.textField}
            onChange={(e) => setEthAmount(e.target.value)}
          />
          {
          tokenAmount > 0
          ?
          (
            <>
            {ethAmount}&nbsp;{net} = {tokenAmount}&nbsp;{name}
            <br/>
            </>
          )
          : null
          }
          <Button 
            variant="contained" 
            sx={{backgroundColor:"#00E676"}}
            onClick={() => buy()}
          >
            buy
          </Button>
        </TabPanel>

        {/* SELL */}
        <TabPanel value="2">
          <TextField 
            variant="outlined" 
            defaultValue="0.0" 
            InputProps={{ endAdornment: <Typography>{name}</Typography> }} 
            className={classes.textField}
            onChange={(e) => setSellTokenAmount(e.target.value)}
          />
          {
          buyEthAmount > 0
          ?
          (
            <>
            {sellTokenAmount}&nbsp;{name} = {buyEthAmount}&nbsp;{net}
            <br/>
            </>
          )
          : null
          }
          {
            isRequireApprove
            ?
            (
              <Button 
                variant="contained" 
                sx={{backgroundColor:"#00E676"}}
                onClick={() => approve()}
              >
                approve
              </Button>
            )
            :
            (
              <Button 
                variant="contained" 
                sx={{backgroundColor:"#F44336"}}
                onClick={() => sell()}
              >
                sell
              </Button>
            )
          }
          
        </TabPanel>
      </TabContext>
    </Box>
  );
}
