import React, { useEffect, useRef, useState } from "react";
import { createChart } from "lightweight-charts";
import { isMobile } from "react-device-detect";

const TradeChart = ({ trades }) => {
  const chartContainerRef = useRef();
  const chartRef = useRef();

  useEffect(() => {
    const chartOptions = {
      layout: {
        textColor: "white",
        background: {
          type: "solid",
          color: "black",
        },
      },
    };

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: isMobile ? 200 : 410,
      ...chartOptions,
    });

    chartRef.current = chart;

    const candlestickSeries = chart.addCandlestickSeries({
      upColor: "#26a69a",
      downColor: "#ef5350",
      borderVisible: false,
      wickUpColor: "#26a69a",
      wickDownColor: "#ef5350",
    });

    const data = trades.map((trade, index) => {
      const open = index === 0 ? trade.price : trades[index - 1].price;
      const close = trade.price;
      return {
        time: Number(trade.timestamp),
        open,
        high: Math.max(open, close),
        low: Math.min(open, close),
        close,
      };
    });

    candlestickSeries.setData(data);

    return () => {
      chart.remove();
    };
  }, [trades]);

  useEffect(() => {
    const handleResize = () => {
      if (chartContainerRef.current && chartRef.current) {
        const width = chartContainerRef.current.clientWidth;
        const height = isMobile ? 200 : 410;
        chartRef.current.resize(width, height);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial resize

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      ref={chartContainerRef}
      style={{
        height: isMobile ? "200px" : "410px",
        width: isMobile ? "100%" : "92%",
        padding: "4px",
        position: "relative",
      }}
    />
  );
};

export default TradeChart;
