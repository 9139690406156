import React, { useState } from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CreateVirtLiqudity from './CreateVirtLiqudity'
import CreateRealLiqudity from './CreateRealLiqudity'

export default function CreateNewPump() {
  const [value, setValue] = useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange} aria-label="swap tabs">
            <Tab sx={{ color: "grey" }} label="Virtual Liquidity" value="1" {...a11yProps(0)} />
            <Tab sx={{ color: "grey" }} label="Real Liquidity" value="2" {...a11yProps(1)}/>
          </TabList>
        </Box>

        {/* VIRT */}
        <TabPanel value="1">
         <CreateVirtLiqudity/>
        </TabPanel>

        {/* REAL */}
        <TabPanel value="2" >
          <CreateRealLiqudity/>
        </TabPanel>
        
      </TabContext>
    </Box>
  );
}
