import React from "react";
import getWeb3 from "../models/getWeb3";
import Web3Context from "../context/Web3Context";
import { Typography } from "@mui/material";

const connectWallet = async (setWeb3, setAccounts, setNetId) => {
  await getWeb3().then(async (response) => {
    setWeb3(response);

    setNetId(await response.eth.net.getId());

    response.eth.getAccounts().then((result) => {
      setAccounts(result);
    });
  });
};

function Wallet(props) {
  const context = React.useContext(Web3Context);

  const web3 = context.web3;
  const setWeb3 = context.setWeb3;
  const setAccounts = context.setAccounts;
  const netId = context.netId;
  const setNetId = context.setNetId;

  return (
    <div>
      {web3 ? (
        <>
          <p style={{ color: "white" }}>Account Connected</p>
        </>
      ) : (
        <div className="login-button">
          <Typography
            variant="body2"
            sx={{ ":hover": { fontWeight: 600 }, cursor: "pointer" }}
            onClick={() => connectWallet(setWeb3, setAccounts, setNetId)}
          >
            [connect wallet]
          </Typography>
        </div>
      )}
    </div>
  );
}

export default Wallet;
